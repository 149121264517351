import React, { Suspense, useLayoutEffect } from "react"
import { Route, Routes } from "react-router-dom"
import { MsalProvider } from "@azure/msal-react"
import { QueryClient, QueryClientProvider } from "@tanstack/react-query"
import ChatbotProvider from "./component/common/Chatbot/ChatbotContext"
import { BusinessProvider } from "./component/context/BusinessContext"

import "./App.css"
import "./assets/scss/style.scss"
const Home = React.lazy(() => import("./features/home/home"))
// Pages
const Login = React.lazy(() => import("./pages/login/Login"))
const Page404 = React.lazy(() => import("./pages/page404/Page404"))
const Page500 = React.lazy(() => import("./pages/page500/Page500"))

const queryClient = new QueryClient()

const App = ({ pca }) => {
  const loading = (
    <div className="pt-3 text-center">
      <div className="sk-spinner sk-spinner-pulse"></div>
    </div>
  )
  useLayoutEffect(() => {
    document.documentElement.style.setProperty("--full-height", `${window.screen.height}px`)
  }, [])

  return (
    <QueryClientProvider client={queryClient}>
      <MsalProvider instance={pca}>
        <BusinessProvider>
          <ChatbotProvider>
            <Suspense fallback={loading}>
              <Routes>
                <Route exact path="/" name="Login" element={<Login />} />
                <Route exact path="/404" name="Page 404" element={<Page404 />} />
                <Route exact path="/500" name="Page 500" element={<Page500 />} />
                <Route path="*" name="Home" element={<Home />} />
              </Routes>
            </Suspense>
          </ChatbotProvider>
        </BusinessProvider>
      </MsalProvider>
    </QueryClientProvider>
  )
}

export default App
