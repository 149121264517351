import React, { createContext, useState, useContext } from "react"
const BusinessContext = createContext()

export const useBusiness = () => useContext(BusinessContext)

const getWeekNumber = (date = new Date()) => {
  const startOfYear = new Date(date.getFullYear(), 0, 1)
  const pastDays = (date - startOfYear) / (24 * 60 * 60 * 1000)
  return Math.ceil((pastDays + startOfYear.getDay() + 1) / 7)
}

export const BusinessProvider = ({ children }) => {
  const [isLoading, setIsLoading] = useState(false)
  const [isReadyToGo, setIsReadyToGo] = useState(false)
  const [timeline, setTimeLine] = useState("week")
  const [year, setYear] = useState(new Date().getFullYear())
  const [value, setValue] = useState(getWeekNumber())
  const [filter, setFilter] = useState({
    TechCenter: null,
    Unit: null,
    Area: null,
    TeamDepartment: null,
  })
  const [Priority, setPriority] = useState("P1")
  const [KPI, setKPI] = useState("Incident")
  const [category, setCategory] = useState("Highlights")
  const [insights, setInsights] = useState([])
  const [filterOption, setFilterOption] = useState("")
  const [filterResponse, setFilterResponse] = useState([])
  const [selectedOption, setSelectedOption] = useState("")

  return (
    <BusinessContext.Provider
      value={{
        isLoading,
        setIsLoading,
        timeline,
        setTimeLine,
        year,
        setYear,
        value,
        setValue,
        filter,
        setFilter,
        KPI,
        setKPI,
        category,
        setCategory,
        insights,
        setInsights,
        isReadyToGo,
        setIsReadyToGo,
        Priority,
        setPriority,
        filterOption,
        setFilterOption,
        filterResponse,
        setFilterResponse,
        selectedOption,
        setSelectedOption,
      }}
    >
      {children}
    </BusinessContext.Provider>
  )
}
