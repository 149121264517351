import React, { createContext, useState, useContext } from "react"
const ChatbotContext = createContext()

export const useChatbot = () => useContext(ChatbotContext)

const ChatbotProvider = ({ children }) => {
  const chatHistory = localStorage.getItem("chatHistory")
  const [isChatbotVisible, setIsChatbotVisible] = useState(false)
  const [messages, setMessages] = useState(chatHistory ? JSON.parse(chatHistory) : [])

  const openChatbot = () => setIsChatbotVisible(true)
  const closeChatbot = () => setIsChatbotVisible(false)

  return (
    <ChatbotContext.Provider
      value={{
        isChatbotVisible,
        messages,
        setMessages,
        openChatbot,
        closeChatbot,
      }}
    >
      {children}
    </ChatbotContext.Provider>
  )
}

export default ChatbotProvider
